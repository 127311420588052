@charset 'utf-8';
@import "~react-image-gallery/styles/css/image-gallery.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poiret One', cursive;
}

.sectionVideo {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(58, 58, 58);
  display: flex;
  z-index: -10;
}

.downloading-alert {
  display: none;
}

.logo-wedding {
  width: 120px;
  margin-top: 20px;
}

.short {
  margin-top: 25vh;
}

.downloading-alert_anim {
  width: auto;
  height: 70px;
  padding:70px 40px 100px 40px;
  font-size: 22px;
  background-color: #f5f5f5;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: absolute;
  z-index: 100000;
  transition: 2s;
  right: 50px;
  box-shadow: 5px 5px 17px rgba(0, 0, 0, 0.137);
  animation: animation1;
  animation-duration: .5s;
  animation-fill-mode: forwards;
}

@keyframes animation1 {
  0% {transform: translateY(-200px);}
  100% {transform: translateY(20px);}
}

.t1, .t2{
  margin-bottom: 5px;
}

.t1 {
  color: rgb(167, 168, 72);
  font-weight: bold;
}


.video-back {
  flex: 1;
  object-fit: cover;
}

.data-over-video {
  width: 100vw;
  height: 100vh;
}

.header-number1 {
  width: 100%;
  height: 180px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  align-items: flex-start;
  justify-content: space-between;
  background: linear-gradient(to bottom, rgb(0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0));
  padding: 0 60px;
}

.logo-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 15px;
}

.logo-container {
  display: flex;
  margin-top: 35px;
}

#learn-more:hover {
  cursor: pointer;
}

.logoTitle {
  font-size: 20px;
  color: white;
}

.play-all {
  display: flex;
  align-items: center;
  color: white;
  font-size: 20px;
  background-color: rgba(190, 125, 51, 0.469);
  padding: 12px 20px;
  border-radius: 25px;
  margin-top: 80px;
}

.play-all i {
  transition: .3s;
}

.play-all:hover {
  cursor: pointer;
}

.play-all:hover i {
  transform: scale(1.2);
  transition: .3s;
}

.play-all p {
  margin-left: 10px;
}

.logoDesc {
  font-size: 15px;
  color: rgb(218, 130, 96);
}

.logo-circle {
  width: 100px;
  height: 100px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: transparent;
  transition: .3s;
}

.logo-circle img {
  width: 100%;
}

.icons-container {
  display: flex;
  align-items: center;
}

.icons-container div {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  margin-top: 30px;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.082);
  margin-left: 10px;
  transition: .2s;
}

.icons-container div i {
  color: white;
  font-size: 16px;
}

.icons-container div:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.469);
  transition: .2s;
}

.row-name {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.row-name h1 {
  position: absolute;
  top: 80px;
  left: 0;
  z-index: 100;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 80px;
  font-family: 'Poiret One', cursive;
}

.trailer {
  font-size: 35px;
  color: white;
}

.video-cards {
  width: 50%;
  height: 300px;
  position: fixed;
  transform: translateX(-50%);
  left: 50%;
  bottom: 20px;
  display: flex;
  justify-content: center;
}

.card-container {
  display: flex;
  width: 250px;
  height: 180px;
  position: relative;
  border-radius: 2px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transform-origin: center;
  background-color: rgb(255, 255, 255);
  box-shadow: 8px 8px 22px rgba(0, 0, 0, 0.466);
  margin-right: 40px;
  margin-left: 40px;
  transition: .4s;
}

.card-container:hover {
  cursor: pointer;
  transform: translateY(-15px);
  transition: .4s;
}

.card-container:hover .i-div {
  transition: .4s;
  opacity: 1;
  transform-origin: center;
}

.card-container:hover i:nth-child(2) {
  transform: translateX(10px);
  transition: .5s;
  opacity: .5;
}

.poster-container {
  flex: 1;
  background-color: rgb(224, 224, 224);
  position: relative;
}

.i-div {
  font-size: 50px;
  color: white;
  position: absolute;
  z-index: 10;
  top: calc(50% - 40px);
  left: 50%;
  transition: .2s;
  opacity: 0;
  transform: translateY(-50%) translateX(-50%);
}

.footer-container {
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70px;
}

.footer-container div {
  display: flex;
  margin-top: 5px;
  justify-content: center;
  align-items: center;
}

.title-wedding {
  font-size: 18px !important;
  color: rgb(155, 126, 63) !important;
  font-weight: bold !important;
}

.footer-container p {
  margin-right: 10px;
  color: rgb(0, 0, 0);
  font-weight: bold;
  font-size: 14px;
}

.footer-container i {
  color: rgb(3, 3, 1);
  font-size: 12px;
  transform: translateX(0);
  transition: .5s;
}

.poster {
  width: 100%;
}

.imageT {
  width: 100%;
}


.slider-gallery {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  transition: .5s;
  background-color: #020000ea;
  animation: anim3;
  animation-duration: .5s;
  animation-fill-mode: forwards;
}

.fadeOut-gallery {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  display: none;
  transition: .2s;
  justify-content: center;
  align-items: center;
  background-color: #020000ea;
  animation: anim2;
  animation-duration: .5s;
  animation-fill-mode: forwards;
}

@keyframes anim2 {
  0% {opacity: 1;}
  100% {opacity: 0; display: none;}
}

@keyframes anim3 {
  0% {opacity: 0;}
  100% {opacity: 1; display: flex;}
}

.close-gallery {
  width: 30px;
  height: 50px;
  position: absolute;
  left: 40px;
  color:white;
  z-index: 100000;
  top: 40px;
}

.close-gallery:hover {
  cursor: pointer;
}

.close-gallery img {
  width: 100%;
}

.fotosButton {
  color: white;
  position: absolute;
  bottom: 0px;
  padding: 10px 0;
  width: 100vw;
  font-size: 25px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  left: 50%;
  z-index: 100;
  transform: translateX(-50%);
}

.fotosButton:hover {
  cursor: pointer;
}



.iconMenu {
  display: none;
}





/*Responsive*/
@media only screen and (max-width: 600px) {
  .header-number1 {
    width: 100%;
    height: 150px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background: linear-gradient(to bottom, rgb(0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0));
    padding: 0 20px;
  }

  .logo-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 15px;
  }

  .short {
    margin-top: 15vh;
  }

  .slider-gallery {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    transition: .5s;
    background-color: #020000ea;
    animation: anim3;
    animation-duration: .5s;
    animation-fill-mode: forwards;
  }
  
  .logo-container {
    display: flex;
    margin-top:10px;
  }

  .logo-wedding {
    width: 80px;
  }

  .logo-wedding:hover {
    cursor: pointer;
  }

  .logo-circle {
    width: 80px;
    height: 80px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid rgb(32, 32, 32);
    border-radius: 50%;
    background-color: rgb(74, 74, 74);
    transition: .3s;
  }
  
  .iconMenu {
    display: flex;
  }

  body {
    background-color: rgb(0, 0, 0);
  }

  .row-name {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .trailer {
    font-size: 20px;
    margin-top: 70px;
    color: white;
  }

  a {
    text-decoration: none;
  }
  
  .row-name h1 {
    position: absolute;
    top: 120px;
    left: 0;
    z-index: 100;
    padding: 0 40px;
    width: 100%;
    text-align: center;
    color: white;
    font-size: 1.7em;
    font-family: 'Poiret One', cursive;
  }

  .play-all {
    display: flex;
    align-items: center;
    color: white;
    font-size: 20px;
    background-color: rgba(190, 125, 51, 0.469);
    padding: 12px 20px;
    border-radius: 25px;
    margin-top: 10px;
  }
  
  .play-all i {
    transition: .3s;
  }

  .sectionVideo {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0px;
    left: 0;
    background-color: rgb(58, 58, 58);
    display: flex;
    z-index: -10;
  }

  .icons-container {
    display: flex;
    align-items: center;
  }
  
  .icons-container div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    margin-top: 30px;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.082);
    margin-left: 10px;
    transition: .2s;
  }

  .fotosButton {
    color: white;
    position: fixed;
    bottom: 0px;
    padding: 10px 0;
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    text-align: center;
    border-top: 1px solid rgb(43, 43, 43);
    background-color: rgba(0, 0, 0, 0.5);
    left: 50%;
    z-index:0;
    transform: translateX(-50%);
  }

  .video-cards {
    width: 100%;
    height: 290px;
    background-color: rgba(8, 8, 8, 0);
    position: fixed;
    transform: translateX(-50%);
    left: 50%;
    bottom: 55px;
    display: flex;
    flex-direction: column;
  }
  
  .card-container {
    display: flex;
    width: 60vw;
    height: calc(100vw * .45);
    position: relative;
    border-radius: 2px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transform-origin: center;
    background-color: rgb(255, 255, 255);
    box-shadow: 8px 8px 22px rgba(0, 0, 0, 0);
    margin-right: 0px;
    margin-left: 0px;
    transition: .4s;
  }
}
